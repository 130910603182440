import { Module } from '@app/models';

export interface ICommonRoute {
  label: string;
  icon?: string;
  isDropdown?: boolean;
  dropdownIsOpen?: boolean;
  withSiderPopup?: boolean;
  featurePermission?: string[];
  link?: string;
  isActive: boolean;
  href?: string;
  searchOperatorIsEqual?: boolean;
  targetBlank?: boolean;
  searchKey?: string[];
  requiredModule?: Module;
  permissionLevel?: number;
  onlyDev?: boolean;
  onlyAdmin?: boolean;
  disableRouter?: boolean;
  children?: ICommonRoute[];
}

export const APP_ADMIN_ROUTES: ICommonRoute[] = [
  {
    label: 'dashboard',
    icon: 'fa-tachometer-alt-fast',
    isDropdown: false,
    withSiderPopup: false,
    link: '/admin/dashboard',
    isActive: false,
    searchKey: ['admin'],
  },
  {
    label: 'properties',
    icon: 'fa-home-lg-alt',
    isDropdown: false,
    withSiderPopup: false,
    link: '/admin/properties',
    isActive: false,
    searchKey: ['properties'],
  },
  {
    label: 'configuration',
    icon: 'fa-cog',
    isDropdown: true,
    dropdownIsOpen: false,
    isActive: false,
    withSiderPopup: false,
    children: [
      {
        label: 'administrators',
        icon: 'fa-user-shield',
        isDropdown: false,
        link: '/admin/administrators',
        isActive: false,
        searchKey: ['administrators'],
      },
      {
        label: 'warnings',
        icon: 'fa-bell',
        isDropdown: false,
        link: '/admin/warnings',
        isActive: false,
        searchKey: ['warnings'],
      },
      {
        label: 'resellers',
        icon: 'fa-handshake-alt',
        isDropdown: false,
        link: '/admin/resellers',
        isActive: false,
        searchKey: ['resellers'],
      },
      {
        label: 'vat_quotes',
        icon: 'far fa-percent',
        isDropdown: false,
        link: '/admin/vat-quotes',
        isActive: false,
        searchKey: ['vat-quotes'],
      },
      {
        label: 'active_electronic_invoicing_modules',
        icon: 'fa-credit-card',
        isDropdown: false,
        link: '/admin/active-electronic-invoicing-modules',
        isActive: false,
        searchKey: ['active-electronic-invoicing-modules'],
      },
      {
        label: 'digital_receipt',
        icon: 'fa-receipt',
        isDropdown: false,
        link: '/admin/digital-receipt',
        isActive: false,
        searchKey: ['digital-receipt'],
      },
      {
        label: 'api_partners',
        icon: 'fa-users',
        isDropdown: false,
        link: '/admin/api-partners',
        isActive: false,
        searchKey: ['api-partners'],
      },
      {
        label: 'city_taxes',
        isDropdown: false,
        link: '/admin/city-tax-presets',
        isActive: false,
        searchKey: ['city-tax-preset'],
        icon: 'fa-city',
      },
    ],
  },
  {
    label: 'utilities',
    icon: 'fa-tools',
    isDropdown: true,
    dropdownIsOpen: false,
    isActive: false,
    withSiderPopup: false,
    children: [
      {
        label: 'channel-tools',
        icon: 'far fa-network-wired',
        isDropdown: false,
        link: '/admin/channel-tools',
        isActive: false,
        searchKey: ['channel-tools'],
      },
      {
        label: 'check_ob',
        icon: 'fa-radar',
        isDropdown: false,
        link: '/admin/check-ob',
        isActive: false,
        searchKey: ['check-ob'],
      },

      {
        label: 'suppressed_email',
        isDropdown: false,
        link: '/admin/suppression-email',
        isActive: false,
        searchKey: ['suppression-email'],
        icon: 'fa-envelope-square',
      },
      {
        label: 'banned_ip',
        icon: 'fa-ban',
        isDropdown: false,
        link: '/admin/banned-ip',
        isActive: false,
        searchKey: ['banned-ip'],
      },
    ],
  },

  {
    label: 'changelog',
    icon: 'fas fa-pen',
    isDropdown: false,
    withSiderPopup: false,
    link: '/admin/changelogs',
    isActive: false,
    searchKey: ['changelog'],
  },
  {
    label: 'access_logs',
    icon: 'fa-history fal',
    isDropdown: false,
    withSiderPopup: false,
    link: '/admin/access-logs',
    isActive: false,
    searchKey: ['access_logs'],
  },
  {
    label: 'wiki',
    icon: 'fa-books',
    isDropdown: false,
    searchKey: ['access_logs'],
    link: 'https://wiki.beddy.dev/',
    targetBlank: true,
    isActive: false,
  },
];

export const APP_COMMON_ROUTES: ICommonRoute[] = [
  {
    label: 'dashboard',
    icon: 'fa-tachometer-alt-fast',
    isDropdown: false,
    withSiderPopup: false,
    link: '/dashboard',
    isActive: false,
    searchKey: ['dashboard'],
    featurePermission: ['any'],
  },
  // {
  //   label: 'my_properties',
  //   icon: 'fa-home-lg-alt',
  //   isDropdown: false,
  //   withSiderPopup: false,
  //   link: '/properties',
  //   isActive: false,
  // },
  {
    label: 'tableau',
    icon: 'fa-th',
    isDropdown: false,
    withSiderPopup: false,
    link: '/tableau',
    isActive: false,
    featurePermission: ['tableau'],
    requiredModule: 'pms',
    searchKey: ['tableau'],
  },
  {
    label: 'reservations',
    icon: 'fa-suitcase-rolling',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    isActive: false,
    children: [
      {
        label: 'reservations_list',
        isDropdown: false,
        link: '/reservations/all',
        isActive: false,
        searchKey: ['reservations/'],
        featurePermission: ['reservations'],
      },
      {
        label: 'guests_presence',
        isDropdown: false,
        link: '/guests-presence/list',
        isActive: false,
        searchKey: ['/guests-presence', 'guest-presence'],
        featurePermission: ['reservations'],
      },
      {
        label: 'price_quotations',
        isDropdown: false,
        link: '/price-quotations/all',
        isActive: false,
        searchKey: ['/price-quotations', 'price-quotation'],
        featurePermission: ['reservations'],
        requiredModule: 'booking-engine-quotes',
      },
      {
        label: 'quote_requests',
        isDropdown: false,
        link: '/quote-requests',
        isActive: false,
        searchKey: ['/quote-requests', 'quote-requests'],
        featurePermission: ['reservations'],
        requiredModule: 'booking-engine-quotes',
      },
      {
        label: 'customers_company',
        isDropdown: false,
        link: '/customers',
        isActive: false,
        searchKey: ['customers', 'customer', 'company', 'companies'],
        featurePermission: ['customers'],
      },
      {
        label: 'daily_meals',
        isDropdown: false,
        link: '/daily-meals',
        isActive: false,
        searchKey: ['daily-meals'],
        featurePermission: ['reservations'],
      },
      {
        label: 'messages_hub',
        isDropdown: false,
        link: '/messages',
        isActive: false,
        searchKey: ['messages'],
        featurePermission: ['reservations'],
      },
    ],
  },
  {
    label: 'documents',
    icon: 'fa-file-alt',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    isActive: false,
    children: [
      {
        label: 'invoices',
        isDropdown: false,
        link: '/documents/invoices',
        isActive: false,
        searchKey: ['/documents/invoices', '/documents/invoice'],
        searchOperatorIsEqual: false,
        requiredModule: 'pms',
        featurePermission: ['invoices'],
      },
      // {
      //   label: 'invoices_receipts',
      //   isDropdown: false,
      //   link: '/documents/invoices/receipts',
      //   isActive: false,
      //   searchKey: ['/invoices/receipts', '/invoice/receipt'],
      // },
      {
        label: 'receipts',
        isDropdown: false,
        link: '/documents/receipts',
        searchKey: ['/documents/receipts', '/documents/receipt'],
        isActive: false,
        searchOperatorIsEqual: false,
        featurePermission: ['invoices'],
        requiredModule: 'pms',
      },
      {
        label: 'open_charges_pass',
        isDropdown: false,
        link: '/documents/open-charges',
        searchKey: ['open-charges', 'open-charge'],
        isActive: false,
        featurePermission: ['invoices'],
        requiredModule: 'pms',
      },
      // {
      //   label: 'istat_guest_cards',
      //   isDropdown: false,
      //   link: '/documents/istat-guest-cards',
      //   isActive: false,
      //   searchKey: ['istat-guest-cards'],
      //   featurePermission: ['property'],
      // },
      {
        label: 'istat_guest_cards',
        isDropdown: false,
        link: '/documents/istat-guests',
        isActive: false,
        searchKey: ['/documents/istat-guests/'],
        searchOperatorIsEqual: false,
        featurePermission: ['property'],
        requiredModule: 'pms',
      },
      // {
      //   label: 'schedine PS',
      //   isDropdown: false,
      //   link: '/documents/schedineps',
      //   searchKey: ['schedineps'],
      //   isActive: false,
      // },
      // {
      //   label: 'customers',
      //   isDropdown: false,
      //   link: '/documents/customers',
      //   searchKey: ['customers', 'customer'],
      //   isActive: false,
      // },
      // {
      //   label: 'companies',
      //   isDropdown: false,
      //   link: '/documents/companies',
      //   searchKey: ['companies', 'company'],
      //   isActive: false,
      // },
    ],
  },
  {
    label: 'accounting',
    icon: 'fa-calculator',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    isActive: false,
    children: [
      {
        label: 'cash_desk',
        isDropdown: false,
        link: '/accounting/cash-desk',
        searchKey: ['cash-desk'],
        isActive: false,
        requiredModule: 'pms',
        featurePermission: ['accounting'],
      },
      // {
      //   label: 'financial_movements',
      //   isDropdown: false,
      //   link: '/accounting/financial/movements',
      //   searchKey: ['movements'],
      //   isActive: false,
      // },
      {
        label: 'suspended',
        isDropdown: false,
        link: '/accounting/suspended',
        searchKey: ['suspended'],
        isActive: false,
        requiredModule: 'pms',
        featurePermission: ['accounting'],
      },
      {
        label: 'fees',
        isDropdown: false,
        link: '/accounting/fees',
        searchKey: ['fees'],
        isActive: false,
        requiredModule: 'pms',
        featurePermission: ['accounting'],
      },
      {
        label: 'accounts_deposits',
        isDropdown: false,
        link: '/accounting/deposits',
        searchKey: ['deposits', 'deposit'],
        isActive: false,
        featurePermission: ['accounting'],
        requiredModule: 'pms',
      },
      {
        label: 'automatic_payments',
        link: '/accounting/upcoming-payments',
        searchKey: ['upcoming-payments'],
        isActive: false,
        featurePermission: ['accounting'],
        requiredModule: 'automatic-payments',
      },
      {
        label: 'city_tax',
        isDropdown: false,
        link: '/accounting/city-tax-summary',
        searchKey: ['city-tax-summary'],
        isActive: false,
        featurePermission: ['accounting'],
        requiredModule: 'pms',
      },
      {
        label: 'daily_closure',
        isDropdown: false,
        link: '/accounting/daily-closure',
        searchKey: ['daily-closure'],
        isActive: false,
        featurePermission: ['accounting'],
        requiredModule: 'pms',
      },
      {
        label: 'cash_closing',
        isDropdown: false,
        link: '/accounting/cash-closing',
        searchKey: ['cash-closing'],
        isActive: false,
        featurePermission: ['accounting'],
        requiredModule: 'pms',
      },
      // {
      //   label: 'expenses_registry',
      //   isDropdown: false,
      //   link: '/accounting/expenses-registry',
      //   searchKey: ['expenses-registry'],
      //   isActive: false,
      //   featurePermission: ['accounting'],
      // },
    ],
  },
  {
    label: 'rates_and_availabilities',
    icon: 'fa-sack-dollar',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    isActive: false,
    children: [
      {
        label: 'daily_overview',
        isDropdown: false,
        link: '/rates-and-availabilities/daily-overview',
        isActive: false,
        searchKey: ['rates-and-availabilities/daily-overview'],
        featurePermission: ['rates'],
        searchOperatorIsEqual: false,
      },
      {
        label: 'bulk_update',
        isDropdown: false,
        link: '/rates-and-availabilities/bulk-update',
        isActive: false,
        searchKey: ['rates-and-availabilities/bulk-update'],
        featurePermission: ['rates'],
        permissionLevel: 2,
        searchOperatorIsEqual: false,
      },
      // {
      //   label: 'bulk_treatments_update',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/treatments-bulk-update',
      //   isActive: false,
      //   searchKey: ['rates-and-availabilities/treatments-bulk-update'],
      //   featurePermission: ['rates'],
      //   permissionLevel: 2,
      //   searchOperatorIsEqual: false,
      // },
      // {
      //   label: 'treatments_overview',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/treatments-daily-overview',
      //   isActive: false,
      //   searchKey: ['rates-and-availabilities/treatments-daily-overview'],
      //   featurePermission: ['rates'],
      //   permissionLevel: 2,
      //   searchOperatorIsEqual: false,
      // },
      {
        label: 'rates_and_offers',
        isDropdown: false,
        link: '/rates-and-availabilities/rates-and-offers',
        isActive: false,
        searchKey: [
          'rateplans-offers-bundles',
          'rateplan-offer-bundle',
          'rates-and-offers',
        ],
        featurePermission: ['ratePlans'],
      },
      {
        label: 'pricelists_rules',
        isDropdown: false,
        link: '/rates-and-availabilities/pricelists-rules',
        isActive: false,
        searchKey: ['pricelists-rules', 'pricelist-rule'],
        featurePermission: ['priceList'],
      },
      // {
      //   label: 'price_lists',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/price-lists',
      //   isActive: false,
      //   searchKey: ['price-lists', 'price-list'],
      // },
      // {
      //   label: 'expired_accommodations',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/expired-accommodations',
      //   isActive: false,
      //   searchKey: ['expired-accommodations', 'expired-accommodation'],
      //   featurePermission: ['accommodations'],
      //   disableRouter: true,
      // },
      // {
      //   label: 'clone_rates',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/clone-rateplans-availabilities',
      //   isActive: false,
      //   searchKey: ['clone-rateplans-availabilities'],
      // },
      // {
      //   label: 'accommodations_rules',
      //   isDropdown: false,
      //   link: '/rates-and-availabilities/accommodations-rules',
      //   isActive: false,
      //   searchKey: ['accommodations-rules', 'accommodation-rule'],
      // },
    ],
  },
  {
    label: 'housekeeper',
    icon: 'fa-broom',
    requiredModule: 'pms',
    isDropdown: false,
    withSiderPopup: false,
    isActive: false,
    link: '/housekeeper/dashboard',
    featurePermission: ['housekeeper'],
    searchKey: ['/housekeeper'],
  },
  // {
  //   label: 'food&beverage',
  //   icon: 'fa-burger-soda',
  //   isDropdown: false,
  //   withSiderPopup: false,
  //   isActive: false,
  //   link: '/food-beverage',
  //   featurePermission: ['property'],
  //   searchKey: ['food-beverage'],
  //   disableRouter: true,
  // },
  {
    label: 'newsletter_page',
    icon: 'fa-envelope',
    isDropdown: true,
    dropdownIsOpen: false,
    featurePermission: ['any'],
    withSiderPopup: false,
    isActive: false,
    requiredModule: 'newsletter',
    children: [
      {
        label: 'newsletter_campaigns',
        requiredModule: 'newsletter',
        isDropdown: false,
        link: '/newsletter',
        isActive: false,
        searchKey: [
          'newsletter/all',
          'newsletter/sent',
          'newsletter/scheduled',
          'newsletter/drafts',
          'newsletter/archived',
        ],
        featurePermission: ['any'],
      },
      {
        label: 'newsletter_mailing_lists',
        requiredModule: 'newsletter',
        isDropdown: false,
        link: '/newsletter/mailing-lists',
        isActive: false,
        searchKey: [
          'newsletter/mailing-lists',
          'newsletter/mailing-lists/details',
        ],
        featurePermission: ['any'],
      },
      {
        label: 'default_emails',
        requiredModule: 'newsletter',
        isDropdown: false,
        link: '/newsletter/default-emails',
        isActive: false,
        searchKey: ['newsletter/default-emails'],
        featurePermission: ['any'],
      },
      {
        label: 'configuration',
        requiredModule: 'newsletter',
        isDropdown: false,
        link: '/newsletter/configuration',
        isActive: false,
        searchKey: ['newsletter/configuration'],
        featurePermission: ['any'],
      },
    ],
  },
  {
    label: 'stats',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    featurePermission: ['statistics'],
    icon: 'fa-chart-line',
    isActive: false,
    children: [
      {
        label: 'revenue_analysis',
        isDropdown: false,
        link: '/stats/revenue',
        isActive: false,
        searchKey: ['/stats/revenue'],
        featurePermission: ['statistics'],
        disableRouter: false,
      },
      {
        label: 'production_analysis',
        isDropdown: false,
        link: '/stats/production',
        isActive: false,
        searchKey: ['/stats/production'],
        featurePermission: ['statistics'],
        disableRouter: false,
      },
      {
        label: 'zdlake',
        isDropdown: false,
        link: '/stats/travel-data-lake',
        isActive: false,
        searchKey: ['/stats/travel-data-lake'],
        featurePermission: ['statistics'],
        requiredModule: 'z_data_lake',
        disableRouter: false,
        onlyDev: false,
      },
    ],
  },
  {
    label: 'configuration',
    icon: 'fa-cog',
    isDropdown: true,
    dropdownIsOpen: false,
    isActive: false,
    withSiderPopup: false,
    children: [
      {
        label: 'general',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'property',
            link: '/configuration/properties',
            isActive: false,
            searchKey: ['/configuration/properties'],
            searchOperatorIsEqual: true,
            featurePermission: ['property'],
          },
          {
            label: 'accommodations',
            link: '/configuration/accommodations',
            isActive: false,
            searchKey: [
              '/configuration/accommodations',
              '/configuration/accommodation',
            ],
            featurePermission: ['accommodations'],
          },

          {
            label: 'users_roles',
            link: '/configuration/users-roles',
            isActive: false,
            featurePermission: ['users', 'roles'],
            searchKey: ['roles', 'role'],
          },
          {
            label: 'operating_params',
            link: '/configuration/operating-params',
            searchKey: ['operating-params'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'cutoff',
            link: '/configuration/cutoff',
            searchKey: ['/configuration/cutoff'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'integrations',
            link: '/configuration/channel-manager',
            searchKey: ['channel-manager'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'events',
            link: '/configuration/events',
            searchKey: ['/configuration/events', '/configuration/event'],
            isActive: false,
            featurePermission: ['property'],
          },
        ],
      },
      {
        label: 'extra_and_fees',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'treatments',
            link: '/configuration/treatments',
            isActive: false,
            searchKey: [
              '/configuration/treatments',
              '/configuration/treatment',
              '/configuration/treatments-daily-overview',
              '/configuration/treatments-bulk-update',
            ],
            featurePermission: ['property'],
          },
          {
            label: 'addons',
            link: '/configuration/addons',
            searchKey: ['addons', 'addon'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'city_tax',
            link: '/configuration/city-tax',
            searchKey: ['configuration/city-tax'],
            isActive: false,
            featurePermission: ['property'],
          },
        ],
      },
      {
        label: 'billing_and_payments',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'billing',
            link: '/configuration/invoicing',
            searchKey: ['invoicing'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'payment_methods',
            link: '/configuration/payment-methods',
            searchKey: ['payment-methods', 'payment-method'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'automatic_payments',
            link: '/configuration/automatic-payments',
            searchKey: ['automatic-payments', 'automatic-payment'],
            isActive: false,
            featurePermission: ['property'],
            requiredModule: 'automatic-payments',
          },
          {
            label: 'cancellation_policies',
            link: '/configuration/cancellation-policies',
            searchKey: ['cancellation-policies', 'cancellation-policy'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'sca_confirmation_module_configuration',
            isActive: false,
            link: '/configuration/sca-confirmation-module',
            featurePermission: ['property'],
            searchKey: ['sca-confirmation-module'],
          },
        ],
      },
      {
        label: 'guest_cards2',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'housed_web',
            link: '/configuration/housed-web',
            searchKey: ['housed-web'],
            isActive: false,
            requiredModule: 'pms',
            featurePermission: ['property'],
          },
          {
            label: 'istat',
            link: '/configuration/istat-groups',
            searchKey: ['istat-groups'],
            isActive: false,
            requiredModule: 'pms',
            featurePermission: ['property'],
          },
        ],
      },
      {
        label: 'booking_engine',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'website_widget',
            link: '/configuration/booking-widgets-configurator',
            searchKey: ['booking-widgets'],
            isActive: false,
            featurePermission: ['property'],
            requiredModule: 'booking-engine',
          },
          {
            label: 'GDPR',
            isActive: false,
            link: '/configuration/gdpr',
            featurePermission: ['property'],
            searchKey: ['gdpr'],
            requiredModule: 'booking-engine',
          },
        ],
      },
      {
        label: 'communications',
        isDropdown: true,
        isActive: false,
        withSiderPopup: false,
        searchKey: ['/configuration/general'],
        featurePermission: ['property'],
        children: [
          {
            label: 'email_templates_messages',
            link: '/configuration/email-templates',
            searchKey: ['email-templates', 'email-template'],
            isActive: false,
            featurePermission: ['property'],
          },
          {
            label: 'notifications_title',
            link: '/configuration/notifications-settings',
            searchKey: ['notifications-settings', 'notification-settings'],
            isActive: false,
            featurePermission: ['property'],
          },
        ],
      },

      // {
      //   label: 'users',
      //   isDropdown: false,
      //   link: '/configuration/users',
      //   searchKey: ['/configuration/users', '/configuration/user'],
      //   isActive: false,
      //   featurePermission: ['users'],
      // },

      // {
      //   label: 'fiscal_printers',
      //   isDropdown: false,
      //   link: '/configuration/fiscal-printers',
      //   isActive: false,
      //   searchKey: ['/configuration/fiscal-printers'],
      //   featurePermission: ['property'],
      // },
      // {
      //   label: 'addons',
      //   isDropdown: false,
      //   link: '/configuration/addons',
      //   searchKey: ['/configuration/addons', '/configuration/addon'],
      //   isActive: false,
      // },
      // {
      //   label: 'addon_categories',
      //   isDropdown: false,
      //   link: '/configuration/addon-categories',
      //   searchKey: [
      //     '/configuration/addon-categories',
      //     '/configuration/addon-category',
      //   ],
      //   isActive: false,
      // },
      // {
      //   label: 'accommodations_mappings',
      //   isDropdown: false,
      //   link: '/configuration/accommodations-mappings',
      //   searchKey: ['accommodations-mappings'],
      //   isActive: false,
      // },
      // {
      //   label: 'extra_beds',
      //   isDropdown: false,
      //   link: '/configuration/extra-beds',
      //   searchKey: ['extra-beds', 'extra-beds'],
      //   isActive: false,
      // },
      // {
      //   label: 'pos',
      //   isDropdown: false,
      //   link: '/configuration/pos',
      //   searchKey: ['/configuration/pos'],
      //   isActive: false,
      // },
      // {
      //   label: 'vat_quotes',
      //   isDropdown: false,
      //   link: '/configuration/vat-quotes',
      //   searchKey: ['vat-quotes', 'vat-quote'],
      //   isActive: false,
      // },
      // {
      //   label: 'children_ranges',
      //   isDropdown: false,
      //   link: '/configuration/children-range',
      //   searchKey: ['children-range'],
      //   isActive: false,
      // },

      // {
      //   label: 'coupons',
      //   isDropdown: false,
      //   link: '/configuration/coupons',
      //   searchKey: ['coupons', 'coupon'],
      //   isActive: false,
      // },
      // {
      //   label: 'reservation_tag',
      //   isDropdown: false,
      //   link: '/configuration/reservation-tag',
      //   searchKey: ['reservation-tag'],
      //   isActive: false,
      // },
    ],
  },
  {
    label: 'exports',
    icon: 'fa-cloud-download fal',
    isDropdown: false,
    withSiderPopup: false,
    isActive: false,
    link: '/exports',
    featurePermission: ['property'],
    searchKey: ['logs'],
  },

  {
    label: 'update_logs',
    icon: 'fa-history',
    isDropdown: false,
    withSiderPopup: false,
    isActive: false,
    link: '/logs',
    featurePermission: ['property'],
    searchKey: ['logs'],
  },
  // {
  //   label: 'comments',
  //   icon: 'fa-thumbs-up',
  //   isDropdown: false,
  //   withSiderPopup: false,
  //   isActive: false,
  //   link: '/comments',
  //   featurePermission: ['property'],
  //   disableRouter: true,
  //   searchKey: ['comments'],
  // },
  // {
  //   label: 'gdpr',
  //   icon: 'fa-user-secret',
  //   isDropdown: false,
  //   withSiderPopup: false,
  //   isActive: false,
  //   link: '/gdpr',
  //   featurePermission: ['property'],
  // },
  {
    label: 'support',
    icon: 'fa-question-circle',
    isDropdown: true,
    dropdownIsOpen: false,
    withSiderPopup: false,
    isActive: false,
    children: [
      {
        label: 'ticket',
        isDropdown: false,
        link: 'https://support.beddy.io/index.php?/Core/Default/Index',
        targetBlank: true,
        searchKey: ['support'],
        isActive: false,
        featurePermission: ['any'],
        disableRouter: false,
      },
      {
        label: 'online_guide',
        isDropdown: false,
        link: 'https://help.beddy.io/home/',
        targetBlank: true,
        searchKey: ['online-guide'],
        isActive: false,
        featurePermission: ['any'],
        disableRouter: false,
      },
      {
        label: 'faq',
        isDropdown: false,
        link: 'https://help.beddy.io/faq',
        targetBlank: true,
        searchKey: ['faq'],
        isActive: false,
        featurePermission: ['any'],
        disableRouter: false,
      },
      {
        label: 'video_guide',
        isDropdown: false,
        link: 'https://www.youtube.com/playlist?list=PLdAkJkBgpWj_ZWKTZj3zmV_97lyr2ff7c',
        targetBlank: true,
        searchKey: ['vudeo-guide'],
        isActive: false,
        featurePermission: ['any'],
        disableRouter: false,
      },
    ],
  },
];
